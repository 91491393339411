@import 'variables.scss';


#closeIcon {
  width: 25px;
  height: 25px;
  // background-color: red;
  // border: 1px solid black;
}
.closeIcon1 {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: black;
  transform: rotate(45deg);
  z-index: 1;
}
.closeIcon2 {
  height: 25px;
  width: 2px;
  background-color: black;
  transform: rotate(90deg);
  z-index: 2;
}


.rotate {
  background: $primary-color;
  display: inline-block;
  margin: auto;
  animation: 1s linear infinite rotate;
  position: relative;
  top: 45px * -1;
  transform-origin: 50% 100%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}


