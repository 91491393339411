@import 'variables.scss';

.page {
  min-height: $page-heigth;
  display: flex;
  flex-direction: column;

  .text-body {
    max-width: 70vw;
    margin: 0 auto;
    font-size: $body-font-size;
  }

  .page-title {
    max-width: 80vw;
    display: inline-block;
    margin: 0 auto;
    font-size: $title-font-size;
    text-transform: uppercase;
    padding-top: 15px;
  }
}

.allcards {
  min-height: 0;
  &.year {
    width: $page-year-width;
  }
}
.detail {
  flex-direction: row;
  height: auto;
  position: relative;
  min-height: 100px;
  background-color: $white;
  margin-bottom: 70px;
}

.page-project {
  background-color: $blue-search;
  color: $black;
  margin: 0;
}
.page-URE {
  background-color: $brown-place;
  color: $black;
  margin: 0;
}
.page-about {
  background-color: $brown-place;
  color: $black;
  margin: 0;
}
.page-qsl {
  background-color: $salmon-qsl;
  color: $black;
  margin: 0;
}

.wrapper-text-column {
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
.text-body-column {
  font-size: $column-font-size;
  width: calc(50% - 2rem);
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 6rem;
  @media (max-width: 700px) {
    width: 90%;
  }

  .page-image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 5vw;
    .page-image {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      span {
        align-self: flex-end;
      }
      img {
        mix-blend-mode: multiply;
        &.v {
          width: 25vw;
          max-height: 100%;
        }
        &.h {
          height: 25vw;
          max-width: 100%;
        }
      }
    }
  }
}

.page-list {
  min-height: 100px;
  margin-bottom: 70px;
}

.page-footnotes {
  font-size: 0.9rem;
  padding-left: 2em;
  ol {
    margin-block-start: 0;
    padding-left: 0em;
    counter-reset: order;
    list-style: none;
  }
  li {
    counter-increment: order;
  }
  li::before {
    content: "0" counter(order) ". ";
  }
}
.text-body-notes {
  font-size: 0.9rem;
  padding-left: 3em;
  padding-top: 5em;
}

.eailPi {
  width: 0px;
}
