@import url('https://fonts.googleapis.com/css?family=Karla:400,700');
@import 'variables.scss';
@import 'icons.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $main-font, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  z-index: 0;
  // padding-top: $header-heigth;
}

.mobile {
  padding-top: $burger-heigth;
}

.header {
  z-index: 50;
  position: sticky;
  top: 0;
}

a {
  text-decoration: none;
  color: $black;
  cursor: url('dot.png'), auto;	
  &:hover {
    text-decoration: underline;
  }
}