#bigLetters {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -2;
}
