@import url(https://fonts.googleapis.com/css?family=Karla:400,700);
@import url(https://fonts.googleapis.com/css?family=Karla:400,700);
@font-face {
  font-family: 'Favorit_Medium';
  src: url("/fonts/Favorit_Medium.ttf") format("truetype");
  src: url("/fonts/Favorit_Medium.woff") format("woff"); }

@font-face {
  font-family: 'Favorit';
  src: url("/fonts/Favorit_Regular.woff.ttf") format("truetype");
  src: url("/fonts/Favorit_Regular.woff.ttf") format("woff"); }

@font-face {
  font-family: 'Favorit_Bold';
  src: url("/fonts/Favorit_Bold.ttf") format("truetype");
  src: url("/fonts/Favorit_Bold.woff") format("woff"); }

@font-face {
  font-family: 'FaktPro';
  src: url("/fonts/FaktPro.ttf"); }

/* #c7b299; */
/* 36px; */
/* 18px; */
/* 25px; */
/* 13px; */
@font-face {
  font-family: 'Favorit_Medium';
  src: url("/fonts/Favorit_Medium.ttf") format("truetype");
  src: url("/fonts/Favorit_Medium.woff") format("woff"); }

@font-face {
  font-family: 'Favorit';
  src: url("/fonts/Favorit_Regular.woff.ttf") format("truetype");
  src: url("/fonts/Favorit_Regular.woff.ttf") format("woff"); }

@font-face {
  font-family: 'Favorit_Bold';
  src: url("/fonts/Favorit_Bold.ttf") format("truetype");
  src: url("/fonts/Favorit_Bold.woff") format("woff"); }

@font-face {
  font-family: 'FaktPro';
  src: url("/fonts/FaktPro.ttf"); }

/* #c7b299; */
/* 36px; */
/* 18px; */
/* 25px; */
/* 13px; */
#closeIcon {
  width: 25px;
  height: 25px; }

.closeIcon1 {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: black;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 1; }

.closeIcon2 {
  height: 25px;
  width: 2px;
  background-color: black;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  z-index: 2; }

.rotate {
  background: black;
  display: inline-block;
  margin: auto;
  -webkit-animation: 1s linear infinite rotate;
          animation: 1s linear infinite rotate;
  position: relative;
  top: -45px;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

body {
  margin: 0;
  padding: 0;
  font-family: "FaktPro", sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  z-index: 0; }

.mobile {
  padding-top: 50px; }

.header {
  z-index: 50;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

a {
  text-decoration: none;
  color: black;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAr0lEQVQ4ja2UYQ3DIBCFPzCwSUDCJFRCJSCpFuYACUiohM5BHdx+cCSXsmwd9JL3h/C+HHAPRAQrYAISsANitAELEBqPMd/VLD+0A7GBKGA9AbBajpAzHXzSrH6mToAAW4X0dlEVnV7Ujf56+kEAQPCDAAAug7wGGdlTXmekEkCgzclZZTuxsQOwo2G0AVz+BDyaFCtopkT+6xE4fAeuzr4t51ykZCqY5QwkEVmP+99Aoegw3HDcpwAAAABJRU5ErkJggg==), auto; }
  a:hover {
    text-decoration: underline; }

@font-face {
  font-family: 'Favorit_Medium';
  src: url("/fonts/Favorit_Medium.ttf") format("truetype");
  src: url("/fonts/Favorit_Medium.woff") format("woff"); }

@font-face {
  font-family: 'Favorit';
  src: url("/fonts/Favorit_Regular.woff.ttf") format("truetype");
  src: url("/fonts/Favorit_Regular.woff.ttf") format("woff"); }

@font-face {
  font-family: 'Favorit_Bold';
  src: url("/fonts/Favorit_Bold.ttf") format("truetype");
  src: url("/fonts/Favorit_Bold.woff") format("woff"); }

@font-face {
  font-family: 'FaktPro';
  src: url("/fonts/FaktPro.ttf"); }

/* #c7b299; */
/* 36px; */
/* 18px; */
/* 25px; */
/* 13px; */
.burgernav-father {
  width: 100%;
  background-color: white;
  height: 50px;
  position: fixed;
  z-index: 1000; }
  .burgernav-father .burgernav-image {
    height: 40px;
    margin-top: 6px;
    margin-left: 3px;
    position: fixed;
    z-index: 1000;
    height: 50; }
  .burgernav-father button {
    position: fixed;
    z-index: 1000;
    border: none;
    background: none; }
  .burgernav-father button:focus {
    outline: none; }
  .burgernav-father #top-burgernav {
    position: fixed;
    margin-top: 50px;
    width: 100%; }
  .burgernav-father .burgernav-links .burger-link:first-child {
    padding-top: 6px; }
  .burgernav-father .burgernav-links .burger-link:last-child {
    padding-bottom: 6px; }

.burger-link {
  background-color: white;
  z-index: 1000;
  padding: 3px 6px; }

.burgernav-project {
  background-color: #d4ebff; }

.burgernav-URE {
  background-color: #ccc8b8; }

.openBurger {
  z-index: 1000;
  height: 50px; }

#bigLetters {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -2; }

@font-face {
  font-family: 'Favorit_Medium';
  src: url("/fonts/Favorit_Medium.ttf") format("truetype");
  src: url("/fonts/Favorit_Medium.woff") format("woff"); }

@font-face {
  font-family: 'Favorit';
  src: url("/fonts/Favorit_Regular.woff.ttf") format("truetype");
  src: url("/fonts/Favorit_Regular.woff.ttf") format("woff"); }

@font-face {
  font-family: 'Favorit_Bold';
  src: url("/fonts/Favorit_Bold.ttf") format("truetype");
  src: url("/fonts/Favorit_Bold.woff") format("woff"); }

@font-face {
  font-family: 'FaktPro';
  src: url("/fonts/FaktPro.ttf"); }

/* #c7b299; */
/* 36px; */
/* 18px; */
/* 25px; */
/* 13px; */
@font-face {
  font-family: 'Favorit_Medium';
  src: url("/fonts/Favorit_Medium.ttf") format("truetype");
  src: url("/fonts/Favorit_Medium.woff") format("woff"); }

@font-face {
  font-family: 'Favorit';
  src: url("/fonts/Favorit_Regular.woff.ttf") format("truetype");
  src: url("/fonts/Favorit_Regular.woff.ttf") format("woff"); }

@font-face {
  font-family: 'Favorit_Bold';
  src: url("/fonts/Favorit_Bold.ttf") format("truetype");
  src: url("/fonts/Favorit_Bold.woff") format("woff"); }

@font-face {
  font-family: 'FaktPro';
  src: url("/fonts/FaktPro.ttf"); }

/* #c7b299; */
/* 36px; */
/* 18px; */
/* 25px; */
/* 13px; */
#closeIcon {
  width: 25px;
  height: 25px; }

.closeIcon1 {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: black;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 1; }

.closeIcon2 {
  height: 25px;
  width: 2px;
  background-color: black;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  z-index: 2; }

.rotate {
  background: black;
  display: inline-block;
  margin: auto;
  -webkit-animation: 1s linear infinite rotate;
          animation: 1s linear infinite rotate;
  position: relative;
  top: -45px;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.tooltip {
  display: inline-block;
  position: relative; }

.tooltip .top {
  min-width: 10px;
  max-width: 100px;
  top: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  padding: 5px;
  color: white;
  background-color: black;
  font-weight: normal;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 3px;
  position: absolute;
  z-index: 99999999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s; }

.tooltip:hover .top {
  visibility: visible;
  opacity: 1; }

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  width: 10px;
  height: 5px;
  overflow: hidden; }

.tooltip .top i::after {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  background-color: #000000;
  box-shadow: 0 1px 8px transparent; }

@font-face {
  font-family: 'Favorit_Medium';
  src: url("/fonts/Favorit_Medium.ttf") format("truetype");
  src: url("/fonts/Favorit_Medium.woff") format("woff"); }

@font-face {
  font-family: 'Favorit';
  src: url("/fonts/Favorit_Regular.woff.ttf") format("truetype");
  src: url("/fonts/Favorit_Regular.woff.ttf") format("woff"); }

@font-face {
  font-family: 'Favorit_Bold';
  src: url("/fonts/Favorit_Bold.ttf") format("truetype");
  src: url("/fonts/Favorit_Bold.woff") format("woff"); }

@font-face {
  font-family: 'FaktPro';
  src: url("/fonts/FaktPro.ttf"); }

/* #c7b299; */
/* 36px; */
/* 18px; */
/* 25px; */
/* 13px; */
.page {
  min-height: calc(100vh - 4.5rem);
  display: flex;
  flex-direction: column; }
  .page .text-body {
    max-width: 70vw;
    margin: 0 auto;
    font-size: 1rem; }
  .page .page-title {
    max-width: 80vw;
    display: inline-block;
    margin: 0 auto;
    font-size: 2rem;
    text-transform: uppercase;
    padding-top: 15px; }

.allcards {
  min-height: 0; }
  .allcards.year {
    width: calc(100vw - 80px); }

.detail {
  flex-direction: row;
  height: auto;
  position: relative;
  min-height: 100px;
  background-color: white;
  margin-bottom: 70px; }

.page-project {
  background-color: #d4ebff;
  color: black;
  margin: 0; }

.page-URE {
  background-color: #ccc8b8;
  color: black;
  margin: 0; }

.page-about {
  background-color: #ccc8b8;
  color: black;
  margin: 0; }

.page-qsl {
  background-color: #fcd3b4;
  color: black;
  margin: 0; }

.wrapper-text-column {
  width: 100%;
  display: flex;
  flex-direction: row; }
  @media (max-width: 700px) {
    .wrapper-text-column {
      flex-direction: column; } }

.text-body-column {
  font-size: 1.25rem;
  width: calc(50% - 2rem);
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 6rem; }
  @media (max-width: 700px) {
    .text-body-column {
      width: 90%; } }
  .text-body-column .page-image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 5vw; }
    .text-body-column .page-image-wrapper .page-image {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%; }
      .text-body-column .page-image-wrapper .page-image span {
        align-self: flex-end; }
      .text-body-column .page-image-wrapper .page-image img {
        mix-blend-mode: multiply; }
        .text-body-column .page-image-wrapper .page-image img.v {
          width: 25vw;
          max-height: 100%; }
        .text-body-column .page-image-wrapper .page-image img.h {
          height: 25vw;
          max-width: 100%; }

.page-list {
  min-height: 100px;
  margin-bottom: 70px; }

.page-footnotes {
  font-size: 0.9rem;
  padding-left: 2em; }
  .page-footnotes ol {
    -webkit-margin-before: 0;
            margin-block-start: 0;
    padding-left: 0em;
    counter-reset: order;
    list-style: none; }
  .page-footnotes li {
    counter-increment: order; }
  .page-footnotes li::before {
    content: "0" counter(order) ". "; }

.text-body-notes {
  font-size: 0.9rem;
  padding-left: 3em;
  padding-top: 5em; }

.eailPi {
  width: 0px; }

@font-face {
  font-family: 'Favorit_Medium';
  src: url("/fonts/Favorit_Medium.ttf") format("truetype");
  src: url("/fonts/Favorit_Medium.woff") format("woff"); }

@font-face {
  font-family: 'Favorit';
  src: url("/fonts/Favorit_Regular.woff.ttf") format("truetype");
  src: url("/fonts/Favorit_Regular.woff.ttf") format("woff"); }

@font-face {
  font-family: 'Favorit_Bold';
  src: url("/fonts/Favorit_Bold.ttf") format("truetype");
  src: url("/fonts/Favorit_Bold.woff") format("woff"); }

@font-face {
  font-family: 'FaktPro';
  src: url("/fonts/FaktPro.ttf"); }

/* #c7b299; */
/* 36px; */
/* 18px; */
/* 25px; */
/* 13px; */
.columns-wrapper {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px; }
  .columns-wrapper div {
    display: 'contents'; }

.column {
  width: 25%;
  flex-basis: 25%;
  padding: 0; }
  @media (max-width: 950px) {
    .column {
      width: 33%;
      flex-basis: 33%; } }
  @media (max-width: 700px) {
    .column {
      width: 50%;
      flex-basis: 50%; } }
  @media (max-width: 450px) {
    .column {
      width: 100%;
      flex-basis: 100%; } }

.list {
  width: 25vh;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  padding-left: 0.5rem; }
  .list li {
    text-decoration: none;
    list-style-type: none;
    font-size: 1.2rem; }
  @media (max-width: 600px) {
    .list {
      width: 33vh; } }

.postcard {
  margin-bottom: 25px;
  padding: 0px 4px 0px 4px;
  margin-bottom: 8px;
  width: calc(100% - 8px); }

.postcard-data {
  color: black;
  text-decoration: none;
  font-size: 0.7rem;
  display: block;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  text-transform: uppercase; }
  .postcard-data .bold {
    font-weight: bold; }

.postcard-thumbnail {
  width: 100%;
  min-width: 100%; }
  .postcard-thumbnail img {
    opacity: 1;
    width: 100%;
    min-width: 100%;
    height: auto;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    transition: opacity 0.5s;
    -webkit-transition: opacity 0.5s; }

.postcard-wrapper {
  display: flex;
  flex-direction: row;
  width: 80vw; }

.postcard-detail-data {
  color: black;
  text-decoration: none;
  font-size: 0.7rem;
  width: 19%; }
  .postcard-detail-data .data-group {
    font-size: 1rem;
    margin-bottom: 2em; }
    .postcard-detail-data .data-group p {
      display: block;
      -webkit-margin-before: 0.5em;
              margin-block-start: 0.5em;
      -webkit-margin-after: 0.5em;
              margin-block-end: 0.5em; }

.close-detail {
  position: absolute;
  top: 0;
  right: 0; }

.postcard-img {
  width: 50%;
  height: auto;
  padding: 5px; }
  .postcard-img img {
    width: 100%;
    height: auto; }

.list-upper {
  text-transform: uppercase; }

@font-face {
  font-family: 'Favorit_Medium';
  src: url("/fonts/Favorit_Medium.ttf") format("truetype");
  src: url("/fonts/Favorit_Medium.woff") format("woff"); }

@font-face {
  font-family: 'Favorit';
  src: url("/fonts/Favorit_Regular.woff.ttf") format("truetype");
  src: url("/fonts/Favorit_Regular.woff.ttf") format("woff"); }

@font-face {
  font-family: 'Favorit_Bold';
  src: url("/fonts/Favorit_Bold.ttf") format("truetype");
  src: url("/fonts/Favorit_Bold.woff") format("woff"); }

@font-face {
  font-family: 'FaktPro';
  src: url("/fonts/FaktPro.ttf"); }

/* #c7b299; */
/* 36px; */
/* 18px; */
/* 25px; */
/* 13px; */
.nav-style {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: block;
  height: 4.5rem;
  width: 100%;
  z-index: 900;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.2rem; }
  .nav-style a {
    color: black;
    font-size: 1.2rem; }
    .nav-style a:hover {
      color: black;
      text-decoration: underline; }
    .nav-style a:visited {
      color: black; }

.nav.burger a {
  color: black;
  font-size: 1.2rem; }
  .nav.burger a:hover {
    color: black;
    text-decoration: underline; }
  .nav.burger a:visited {
    color: black; }

.nav-row-wrapper {
  width: 100%;
  display: flex; }

.nav-row {
  display: flex;
  margin: 0 auto;
  background-color: white;
  padding: 0;
  align-items: flex-start;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 100%; }

.nav-group {
  width: 25%;
  display: block;
  border-color: black;
  padding-top: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
  .nav-group.logo {
    padding-top: 0.5rem; }

.nav-lang {
  position: absolute;
  right: 0;
  padding-top: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
  .nav-lang span {
    display: block;
    margin: 0;
    height: 1.8rem; }

.nav-project {
  background-color: #d4ebff; }

.nav-ure {
  background-color: #ccc8b8; }

.nav-about {
  background-color: #ccc8b8; }

.nav-qsl {
  background-color: #fcd3b4; }

.nav-home {
  background-color: transparent; }

.nav-link {
  font-family: "FaktPro", sans-serif;
  font-size: 1rem;
  width: 100%;
  display: inline-block; }
  .nav-link:hover {
    text-decoration: underline; }
  .nav-link.search-link {
    text-decoration: none; }

.menuItem {
  height: 1.8rem; }

.link {
  font-family: "FaktPro", sans-serif;
  font-size: 1rem;
  display: inline-block; }
  .link:hover {
    text-decoration: underline; }

.link-line {
  display: inline;
  margin-right: 10px; }
  .link-line:hover {
    text-decoration: underline; }

.logoSvg {
  top: 0;
  width: calc(4.5rem + 15px);
  height: auto;
  padding: 5px 0; }

.logo {
  align-self: start; }

#top-nav {
  color: black;
  width: 100%; }

.bar-Year {
  width: calc(100vw - 80px); }

.bar-years {
  background-color: white; }
  .bar-years a {
    color: black; }

.bar-ure {
  background-color: #ccc8b8; }

.bar-about {
  background-color: #ccc8b8; }

.bar-qsl {
  background-color: #fcd3b4; }

.bar-home {
  background-color: transparent; }

.year-wrapper {
  width: 80px;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0;
  overflow: scroll;
  padding-top: 10px;
  z-index: 51; }

.year-wrapper-v {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  align-items: flex-start;
  justify-content: center; }

.menu-years-v {
  display: inline-block;
  padding: 0 0 0 10px;
  margin: 2px;
  width: 17px; }

.year-column {
  font-size: 1.2rem;
  line-height: 1.2rem; }
  .year-column a {
    color: black;
    font-size: 1.2rem; }
    .year-column a:hover {
      color: black; }
    .year-column a:visited {
      color: black; }

.searchField {
  color: black;
  font-size: 1rem;
  position: relative;
  width: 50%; }

.searchInput {
  font-size: 1rem;
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1.5px solid black;
  outline: 0;
  padding-top: 2px;
  color: black;
  background: transparent;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s; }
  .searchInput::-webkit-input-placeholder {
    font-size: 1rem;
    color: #cccccc;
    /* transparent; */ }
  .searchInput::-ms-input-placeholder {
    font-size: 1rem;
    color: #cccccc;
    /* transparent; */ }
  .searchInput::placeholder {
    font-size: 1rem;
    color: #cccccc;
    /* transparent; */ }
  .searchInput:placeholder-shown ~ .searchLabel {
    cursor: text;
    top: 20px; }

.searchLabel {
  position: absolute;
  top: 0;
  display: block;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 1rem;
  color: black;
  /* $brown-place; */ }

.searchInput:focus {
  border-width: 1.5px;
  border-image-slice: 1; }
  .searchInput:focus ~ .searchLabel {
    position: absolute;
    top: 0;
    display: block;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    font-size: 1rem;
    color: black;
    font-weight: 700; }

.searchInput:required, .searchInput:invalid {
  box-shadow: none; }

