@font-face {
  font-family: 'Favorit_Medium';
  src: url('/fonts/Favorit_Medium.ttf') format('truetype');
  src: url('/fonts/Favorit_Medium.woff') format('woff');
}
@font-face {
  font-family: 'Favorit';
  src: url('/fonts/Favorit_Regular.woff.ttf') format('truetype');
  src: url('/fonts/Favorit_Regular.woff.ttf') format('woff');
}
@font-face {
  font-family: 'Favorit_Bold';
  src: url('/fonts/Favorit_Bold.ttf') format('truetype');
  src: url('/fonts/Favorit_Bold.woff') format('woff');
}

@font-face {
  font-family: 'FaktPro';
  src: url('/fonts/FaktPro.ttf');
}

$white: white;
$black: black;
$pink-archive: #ff3e8d;
$red-intro: #ea0032;
$yellow-year: #fcee21;
$grey-menu: #333333;
$brown-place: #ccc8b8; /* #c7b299; */
$blue-search: #d4ebff;
$salmon-qsl:#fcd3b4;
$green-project: #71e2a7;
$blue-alt: #2e3192;
$yellow-alt: #fff3b6;
$pink-alt: #ffebf3;
$grey-alt: #cccccc;
$dark-grey: #4d4d4d;
$semi-orange: #E9521E;

$primary-color: $black;
$secondary-color: $white;
$accent-color: $black;
$background-color: $white;

$header-heigth: 4.5rem;
$burger-heigth: 50px;
$page-heigth: calc(100vh - #{$header-heigth});
$nav-height: 80px;
$contextual-height: 80px;
$year-width: 80px;
$page-year-width: calc(100vw - #{$year-width});

$nav-font-size: 1.2rem;
$title-font-size: 2rem; /* 36px; */
$body-font-size: 1rem; /* 18px; */
$column-font-size: 1.25rem; /* 25px; */
$detail-font-size: 0.7rem; /* 13px; */

$main-font: 'FaktPro', sans-serif;
$accent-font: 'FaktPro', sans-serif;



// Update Bulma's global variables
$family-sans-serif: 'FaktPro', sans-serif;
$grey-dark: $grey-menu;
$grey-light: $brown-place;
$primary: $pink-archive;
$link: $blue-search;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-background-color: $brown-place;
$input-border-color: transparent;
$input-shadow: none;
