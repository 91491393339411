@import 'variables.scss';
.burgernav-father {
  width: 100%;
  background-color: $white;
  height: $burger-heigth;
  position: fixed;
  .burgernav-image {
    height: 40px;
    margin-top: 6px;
    margin-left: 3px;
    position: fixed;
    z-index: 1000;
    height: 50;
  }

  button {
    position: fixed;
    z-index: 1000;
    border: none;
    background: none;
  }
  button:focus {
    outline: none;
  }

  z-index: 1000;
  #top-burgernav {
    position: fixed;
    margin-top: 50px;
    width: 100%;

  }
  .burgernav-links {
    .burger-link:first-child {
      padding-top: 6px;
    }

    .burger-link:last-child {
      padding-bottom: 6px;
    }
  }
}
.burger-link {
  background-color: $white;
  z-index: 1000;
  padding: 3px 6px;
}

.burgernav-project {
  background-color: $blue-search;
}
.burgernav-URE {
  background-color: $brown-place;
}

.openBurger {
  z-index: 1000;
  height: 50px;
}
