@import 'variables.scss';

.columns-wrapper {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //max-width: 70vw;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  & div {
    display: 'contents';
  }
}

.column {
  width: 25%;
  flex-basis: 25%;
  padding: 0;
  @media (max-width: 950px) {
    width: 33%;
    flex-basis: 33%;
  }
  @media (max-width: 700px) {
    width: 50%;
    flex-basis: 50%;
  }
  @media (max-width: 450px) {
    width: 100%;
    flex-basis: 100%;
  }
}

.list {
  width: 25vh;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;

  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  padding-left: 0.5rem;

  & li {
    text-decoration: none;
    list-style-type: none;
    font-size: $nav-font-size;
  }
  @media (max-width: 600px) {
    width: 33vh;
  }
}

.postcard {
  margin-bottom: 25px;
  // margin-left: 1vw;
  // margin-right: 1vw;
  padding: 0px 4px 0px 4px;
  margin-bottom: 8px;
  width: calc(100% - 8px);
}

.postcard-data {
  color: $black;
  text-decoration: none;
  font-size: $detail-font-size;
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-transform: uppercase;
  .bold {
    font-weight: bold;
  }
}

.postcard-thumbnail {
  width: 100%;
  min-width: 100%;
  img {
    // background-color: $dark-grey;
    opacity: 1;
    width: 100%;
    min-width: 100%;
    height: auto;
    // min-height: 20vw;
    transition-delay: 0.1s;
    transition: opacity 0.5s;
    -webkit-transition: opacity 0.5s;
  }
}

.postcard-wrapper {
  display: flex;
  flex-direction: row;
  // margin: 0 auto;
  width: 80vw;
  // background-color: $grey-alt;
}

.postcard-detail-data {
  color: $black;
  text-decoration: none;
  font-size: $detail-font-size;
  width: 19%;

  .data-group {
    font-size: $body-font-size;
    p {
      display: block;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
    margin-bottom: 2em;
  }
}

.close-detail {
  position: absolute;
  top: 0;
  right: 0;
}

.postcard-img {
  width: 50%;
  height: auto;
  padding: 5px;
  img {
    width: 100%;
    height: auto;
  }
}

.list-upper {
  text-transform: uppercase;
}
