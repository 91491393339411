@import 'variables.scss';

.nav-style {
  position: sticky;
  top: 0;
  display: block;
  height: $header-heigth;
  width: 100%;
  z-index: 900;
  margin: 0;
  padding: 0;
  font-size: $nav-font-size;
  line-height: $nav-font-size;
  & a {
    color: $black;
    font-size: $nav-font-size;

    &:hover {
      color: $accent-color;
      text-decoration: underline;
    }
    &:visited {
      color: $black;
    }
  }
}
.nav.burger {
  & a {
    color: $black;
    font-size: $nav-font-size;

    &:hover {
      color: $accent-color;
      text-decoration: underline;
    }

    &:visited {
      color: $black;
    }
  }
}

.nav-row-wrapper {
  width: 100%;
  display: flex;
}
.nav-row {
  // position: fixed;
  display: flex;
  margin: 0 auto;
  background-color: $white;
  padding: 0;
  align-items: flex-start;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 100%;
  &.nav-year {
  }
}
.nav-group {
  width: 25%;
  display: block;
  border-color: black;
  padding-top: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &.logo{
    padding-top: 0.5rem;
  }
}
.nav-lang {
  position: absolute;
  right: 0;
  padding-top: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  span {
    display: block;
    margin: 0;
    height: 1.8rem;
  }
}

.nav-project {
  background-color: $blue-search;
}
.nav-ure {
  background-color: $brown-place;
}
.nav-about {
  background-color: $brown-place;
}
.nav-qsl {
  background-color: $salmon-qsl;
}
.nav-home {
  background-color: transparent;
}

.nav-link {
  font-family: $main-font;
  font-size: 1rem;
  width: 100%;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
  &.search-link {
    text-decoration: none;
  }
}

.menuItem{
  height: 1.8rem;
}

.link {
  font-family: $main-font;
  font-size: 1rem;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
}

.link-line {
  display: inline;
  &:hover {
    text-decoration: underline;
  }
  margin-right: 10px;
}

.logoSvg {
  top: 0;
  width: calc(#{$header-heigth} + 15px);
  height: auto;
  padding: 5px 0;
}

.logo {
  align-self: start;
}

#top-nav {
  color: $black;
  width: 100%;
}

.bar-Year {
  width: $page-year-width;
}
.bar-years {
  background-color: $white;
  & a {
    color: $black;
  }
}
.bar-ure {
  background-color: $brown-place;
}
.bar-about {
  background-color: $brown-place;
}
.bar-qsl {
  background-color: $salmon-qsl;
}
.bar-home {
  background-color: transparent;
}

.year-wrapper {
  width: $year-width;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0;
  overflow: scroll;
  padding-top: 10px;
  z-index: 51;
}

.year-wrapper-v {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  align-items: flex-start;
  justify-content: center;
}
.menu-years-v {
  display: inline-block;
  padding: 0 0 0 10px;
  margin: 2px;
  width: 17px;
}

.year-column {
  font-size: $nav-font-size;
  line-height: $nav-font-size;
  & a {
    color: $black;
    font-size: $nav-font-size;

    &:hover {
      color: $accent-color;
    }
    &:visited {
      color: $black;
    }
  }
}

.searchField {
  color: $black;
  font-size: $body-font-size;
  position: relative;
  width: 50%;
}

.searchInput {
  font-size: $body-font-size;
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1.5px solid $primary-color;
  outline: 0;
  padding-top: 2px;
  color: $primary-color;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    font-size: $body-font-size;
    color: $grey-alt; /* transparent; */
  }

  &:placeholder-shown ~ .searchLabel {
    cursor: text;
    top: 20px;
  }
}

.searchLabel {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $primary-color; /* $brown-place; */
}

.searchInput:focus {
  ~ .searchLabel {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary-color;
    font-weight: 700;
  }
  border-width: 1.5px;
  border-image-slice: 1;
}

.searchInput {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}
